// @ts-nocheck
import { Component, OnInit, Renderer2 } from '@angular/core';
// import { DatePipe } from '@angular/common/src/pipes';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import dayGridPlugin from '@fullcalendar/daygrid';
import { formatDate } from '@fullcalendar/core';
import { format } from 'url';
import moment from 'moment';
import { throws } from 'assert';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {

	data = [];
	customDate = 0;
	date:any;
	status = '';
	reschedule=[];
	therapists=[];
	selectedTherapist=0;
	rescheduleDate= "";
	freeSlots = [];
	rescheduleId:any;
	departments = [];
	regSlot=0;
	regConsultant=0;
	regDate:any;
	repeatType = 0;
	repeatCount = 2;
	selectedSlot = {"visits":[], "notes":[], "upcoming":[], "upcoming_months":[], "past":[], "past_months":[]};
	upcoming = [];
	cancelMode = 0;
	cancelUpcoming = 0;
	allow_edit = localStorage.getItem('allow_edit');
	groupSlots = [];
	consultations = [];
	role = localStorage.getItem('role_id');
	runSchedule:any;
	cachedSlotCount = 0;
	cancelView=0;
	nextReg="";
	selectedDept = 0;
	selectedDeptName = "";
	cc = "+91";
	flag = "";
	enquiry:any = {patient_name:""};


	/* for viewSlot */

	selectedMonth = "";
	cancelSlotId = "";
	cancelFrom = '0';
	openSlot = {"slot_id":"", "therapist_id":""}; //slot that's currently open for viewing.
	other_appointments = [];
	other_months = [];
	appointmentsMode = 1;
	selectedNote:any = {};

	selectedVisit = { "appointment_id":"", "upcoming":[], "upcoming_months":[], "past":[], "past_months":[]};

	calendarPlugins = [dayGridPlugin]; // fullcalendar!
	calendarEvents = [
                        { title: 'event 1', date: '2020-02-29' },
						{ title: 'event 2', date: '2020-02-11' }
					  ];
	
	view_slot = 0;					  

  	constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) {
		
  		this.router.routeReuseStrategy.shouldReuseRoute = function() {
	        return false;
	    };
  	}

	ngOnInit() {
		var self = this;
		/*if(localStorage.getItem('scheduleDate') === null ) {
			this.date = 0;
		} else {
			//this.date = localStorage.getItem('scheduleDate');
		}*/

		this.loadDepartments();

		this.date = this.dataService.getLocalData("dateSchedule");
		this.customDate = this.dataService.getLocalData("dateSchedule");
		this.selectedDept = this.dataService.getLocalData("deptSchedule");
		this.selectedDeptName = this.dataService.getLocalData("deptScheduleName");
		
		
		this.flag = this.route.snapshot.paramMap.get('flag');

		if(this.flag == "enquiry"){
			this.enquiry = this.dataService.localData.slotEnquiry;
			console.log(this.enquiry);
		}
		
		
		this.getTherapists();
		
		this.loadDate(this.date);
		//this.runSchedule = window.setInterval(() => this.getAppointments(), 60000);



		


		//datepicker
		
		$( function() {
			(<any>$("#reschedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	beforeShow: function() {
			        setTimeout(function(){
			            $('.ui-datepicker').css('z-index', 99999999999999);
			        }, 0);
			    },
			    onSelect: function (dateText, inst) {
					this.focus();
			    }
			});
		});

		$( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	onSelect: function (dateText, inst) {
					this.focus();

			    }
			});

			
		});

		




		
	}

	ngOnDestroy() {
	  if (this.runSchedule) {
	    clearInterval(this.runSchedule);
	  }
	}

	

	loadDate(date) {
		
		this.date = date;
		this.dataService.setLocalData("dateSchedule", date);
		//var dept = $('#dropdownMenuButton').attr('data-dept');
		this.dataService.setLocalData("deptSchedule", this.selectedDept);
		this.dataService.setLocalData("deptScheduleName", this.selectedDeptName);
		
		this.dataService.getDate(date, this.selectedDept, this.customDate, this.cancelView).subscribe( (data: any["selected"] )=> {
			this.data = data;
			this.date = data.selected;
			console.log(this.data);

			this.getAppointments();
			
			
		} );
		
	}

	loadDepartments() {
		this.dataService.getDepts().subscribe( (data: any["selected"] )=> {
			this.departments = data;
			if(this.selectedDeptName == ""){
				//@ts-ignore
				this.selectedDeptName = this.departments.departments[0].name;
			}
			
			
		} );
	}
	

	openRegistration(date){
		$('#inputRegNo').val( $('#inputNameApp').val() );
		this.regDate = date;
			
		this.dataService.getNextPatientId().subscribe( (data: any[] ) =>{
	          //@ts-ignore
	          if (data.status == 0) {
	            //@ts-ignore
	            this.nextReg = data.reg_no;
	          }
	          
	      });

		
	}

	openAppointment(consultant, slot, selected){
		this.selectedTherapist = consultant;	  	
		this.regConsultant = consultant;
		this.regSlot = slot;
		$('#inputConsultant').val(consultant);
		$('#inputSlotId').val(slot);
		$('#inputDate').val(selected);
		
		
	}

	changeDept(dept, dept_id) {
		//$('#dropdownMenuButton').text(dept);
		//$('#dropdownMenuButton').attr('data-dept', dept_id);
		this.selectedDept = dept_id;
		this.selectedDeptName = dept;
		this.loadDate(this.date);

		
		
	}

	cancelAppointment(type, appointment_id) {
		var cnf = confirm("Are you sure you want to cancel this appointment?");

		if(cnf) {
			this.dataService.cancelAppointment(type, appointment_id).subscribe( (res:any) => {
				if(res.status == 0) {
		        	alert(res.msg);
		        	this.getAppointments();
		        
		      	} else {
		        	alert(res.msg);
		      	}
			} );
		}


	}

	loadReschedule(appointment_id) {
		console.log('reschedule');
		$('#rescheduleModal').modal('show');

		this.rescheduleId = appointment_id;
		this.dataService.loadReschedule(appointment_id).subscribe( (data: any[] )=> {
			this.reschedule = data;
			
		} );
	}

	getTherapists(){
		this.dataService.getTherapists().subscribe( (data: any[] )=> {
			this.therapists = data;
			
		} );	
	}

	setRescheduleDate(val) {
		
		/*if (moment(val, 'DD-MM-YYYY',true).isValid()) {
			
		}*/
		this.rescheduleDate = val;
		this.getSlots(); 

		
	}

	changeTherapist(val){
		this.selectedTherapist = val;
		this.getSlots();
	}

	getSlots() {
		this.dataService.getSlots(this.rescheduleDate, this.selectedTherapist).subscribe( (res: any["status"] )=> {
			if (res.status==0) {
				this.freeSlots = res.slots;
			}
			
		} );	
	}

	rescheduleSlot() {
		var data = {
			"date":this.rescheduleDate,
			"therapist":this.selectedTherapist,
			"appointment_id":this.rescheduleId,
			"slot":"",
		};

		var selectedSlot = $('ul.rescheduleSlotSelector li.active').attr('data-slot');
		data.slot = selectedSlot;

		this.dataService.postReschedule(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        $('#rescheduleModal').modal('hide');
		        $('#reschedule-date').val('');
		        this.freeSlots = [];
		        //this.loadDate(this.date);
		        this.getAppointments();
		        this.viewVisit(this.rescheduleId);
		        
	      	} else {
	        	alert(res.msg);
	      	}

		} );

	
	}

	replace(slot_id) {
		$('#inputReplace').val(slot_id);
		$('#addAppointment').modal('show');
	}

	markHoliday(date) {
		var data = {"date":date};
		var cnf = confirm("Are you sure you want to mark this date as holiday?");

		if(cnf) {
			this.dataService.postMarkHoliday(data).subscribe((res:any) => {
			
				if(res.status == 0) {
			        alert(res.msg);
			        this.loadDate(this.date);
			        
		      	} else {
		        	alert(res.msg);
		      	}
			});
		}


		

	}

	unmarkHoliday(date) {
		var data = {"date":date};
		var cnf = confirm("Are you sure you want to unmark this date as holiday?");

		if(cnf) {
			this.dataService.postUnmarkHoliday(data).subscribe((res:any) => {
			
				if(res.status == 0) {
			        alert(res.msg);
			        this.loadDate(this.date);
			        
		      	} else {
		        	alert(res.msg);
		      	}
			});
		}


		

	}

	markLeave(consultant, mode) {
		var data = {"consultant":consultant, "date":this.date, mode:mode};
		var cnf = confirm("Are you sure you want to change status of this consultant?");

		if(cnf) {
			this.dataService.postMarkLeave(data).subscribe((res:any) => {
				if(res.status == 0) {
			        alert(res.msg);
			        this.loadDate(this.date);
			        
		      	} else {
		        	alert(res.msg);
		      	}
			});
		}

		
	}

	unmarkLeave(consultant) {
		var data = {"consultant":consultant, "date":this.date};
		var cnf = confirm("Are you sure you want to unmark status of this consultant?");

		if(cnf) {
			this.dataService.postUnmarkLeave(data).subscribe((res:any) => {
				if(res.status == 0) {
			        alert(res.msg);
			        this.loadDate(this.date);
			        
		      	} else {
		        	alert(res.msg);
		      	}
			});
		}

		
	}

	submitRegistrationSchedule(data) {
	    data.dob = $('#inputDOB1').val();
	    data.patient_since = $('#inputSince1').val();
	    data.oldregno = $('#inputRegNo').val();
	    $('#btn-reg').attr('disabled', 'true');
	    $('#btn-reg').text('Submitting');

	    var treatments = [];

	    $('ul.treatment-selector li').each(function(){
	      if( $(this).hasClass('active') ) {
	        treatments.push( $(this).text() );
	      }
	    })

	    data.treatments = treatments;
	    data.regSlot = this.regSlot;
	    data.regDate = this.regDate;
	    data.regConsultant = this.regConsultant;
	    data.is_appointment = 1;

	    

	    
	    
	    this.dataService.postRegistration(data).subscribe( (res:any) => {
	    	$('#btn-reg').removeAttr('disabled');
	    	$('#btn-reg').text('Submit');
	      
	      if(res.status == 0) {
	        alert(res.msg);
	        $('#addPatientSchedule').modal('hide');
	        //@ts-ignore
	        document.getElementById("formRegs").reset(); 
	        $('.treatment-selector li').each(function(){
	        	$(this).removeClass('active');
	        });
	        //this.loadDate(this.date);
	        this.getAppointments();
	        
	      } else {
	        alert(res.msg);
	      }

	    }); 

	    

	    
	}

	submitAppointment() {
		var data = {
	      "consultant_id": $('#inputConsultant').val(),
	      "patient_id": $('#inputPatientId').val(),
	      "slot_id": $('#inputSlotId').val(),
	      "visit_purpose": $('#inputPurpose').val(),
	      "date":$('#inputDate').val(),
	      "repeat_type": this.repeatType,
	      "repeat_count": this.repeatCount,
		  "dept":this.selectedDept,
		  "is_enquiry":0,
		  "enquiry_id":null
	    };



	    if(data.patient_id == "" || data.patient_id == 0 ) {
			
			if(this.flag == "enquiry" && this.enquiry.id != 0){
				data.enquiry_id = this.enquiry.id;
				console.log(this.enquiry.id);
				data.is_enquiry = 1;

				$('#btn-appointment').attr('disabled', 'true');
				$('#btn-appointment').text('Submitting');
				
				this.dataService.postAppointmentEnquiry(data).subscribe( (res:any) => {
					$('#btn-appointment').removeAttr('disabled');
					$('#btn-appointment').text('Add Appointment');
					this.repeatType = 0;
					if(res.status == 0) {
					alert(res.msg);
					$('#addAppointment').modal('hide');
					//@ts-ignore
					document.getElementById("formApp").reset(); 
					
					//this.loadDate(this.date);
					this.getAppointments();
					
					} else {
					alert(res.msg);
					}
				});

			} else {
				alert('Select patient from suggestions');
			}
			
			

		  
	    } else {

	    	
	      $('#btn-appointment').attr('disabled', 'true');
	      $('#btn-appointment').text('Submitting');
	      
	      this.dataService.postAppointment(data).subscribe( (res:any) => {
	      	$('#btn-appointment').removeAttr('disabled');
	      	$('#btn-appointment').text('Add Appointment');
	      	this.repeatType = 0;
	        if(res.status == 0) {
	          alert(res.msg);
	          $('#addAppointment').modal('hide');
	          //@ts-ignore
	          document.getElementById("formApp").reset(); 
	          
	          //this.loadDate(this.date);
	          this.getAppointments();
	          
	        } else {
	          alert(res.msg);
	        }
	       });
	     
	    }

	    


	}

	deleteNote(note_id){
		
		var cnf = confirm("Are you sure you want to delete this note?");

		if(cnf) {
			var data = {"note_id":note_id};
			this.dataService.postDeleteSlotNote(data).subscribe((res:any) => {
			
				if(res.status == 0) {
					//alert(res.msg);
					//this.loadDate(this.date);
					this.getAppointments();
					this.viewSlot(this.openSlot.slot_id, this.openSlot.therapist_id,0, 1, 0);
					
				} else {
					alert(res.msg);
				}
			});
		}

		

	}

	submitSlotNote() {
		
		var data = {
			"note" : $('#inputSlotNote').val(),
			"slot_id" : $('#inputSlotId').val(),
			"date":$('#inputDate').val(),
			"consultant_id": $('#inputConsultant').val(),
			"dept_id":this.selectedDept,
		}

		$('#btn-slotnote').attr('disabled', 'true');
	    $('#btn-slotnote').text('Submitting');

		this.dataService.postSlotNote(data).subscribe( (res:any) => {
			$('#btn-slotnote').removeAttr('disabled');
	      	$('#btn-slotnote').text('Add Note');

	      	if(res.status == 0) {
	          alert(res.msg);
	          $('#addAppointment').modal('hide');
	          //@ts-ignore
	          document.getElementById("formSlotNote").reset(); 
	          
	          //this.loadDate(this.date);
	          this.getAppointments();
	          
	        } else {
	          alert(res.msg);
	        }

		});
	}

	setRepeatType(val) {
		this.repeatType = val;
		
		
	}

	setRepeatCount(val){
		this.repeatCount = val;

	}

	

	switchDate(val) {

		if (val !== null) {
			this.customDate = val;
			this.date = val;
			this.loadDate(this.date);
		}
		
		
	}

	showDetails(id, docid, count){
		if (docid != 0) {
			this.selectedTherapist = docid;
		}

		//this.upcoming = [];

		this.dataService.getVisitDetails(id).subscribe( (data: any[] )=> {
				//@ts-ignore
				this.selectedSlot = data.data;

				
			} );
		if (count==0) {
			count = this.cachedSlotCount;
		} else {
			this.cachedSlotCount = count;
		}

		if (count == 1) {
			//get upcoming appointments;
			this.groupSlots = [];
			

			this.dataService.getUpcoming(id).subscribe( (data: any[] )=> {
				//@ts-ignore
				this.upcoming = data.data;
				
			} );



		} else{
			this.upcoming = [];
			//get group appointments;
			this.dataService.getGroupAppointments(id).subscribe((data: any[] )=>{
				//@ts-ignore
				this.groupSlots = data;
				console.log(data);
				
			});

		}

		
		
		
	}



	viewSlot( slot_id, therapist_id, selected, show_group, appointment_id, is_enquiry=0){

		

			if (therapist_id != 0) {
				this.selectedTherapist = therapist_id;
			}
	
	
	
			this.openSlot.slot_id = slot_id;
			this.openSlot.therapist_id = therapist_id;
	
			if (show_group == 1) {
	
				this.dataService.getViewSlot(slot_id, therapist_id, this.date, selected).subscribe( (data: any[] )=> {
					//@ts-ignore
					this.selectedSlot = data;
					
					
					
				} );
				$('#slotModal').modal('show');
	
	
	
			}else {
	
	
				
				this.viewVisit(appointment_id);
	
				
			}

		

		

		


	}

	viewVisit(appointment_id){

		this.dataService.getViewVisit(appointment_id).subscribe( (data: any[] )=> {

				//@ts-ignore
				this.selectedVisit = data;
				//@ts-ignore
				this.other_appointments = data.upcoming;
				//@ts-ignore
				this.other_months = data.upcoming_months;
				//@ts-ignore
				this.selectedMonth = data.upcoming_months[0];
				this.appointmentsMode = 1;
				this.selectedVisit.appointment_id = appointment_id;

				var temp=[];

				this.other_appointments.forEach(function(value,index){
					temp.push({title: moment(value.app_time).format('h:mm a'), date: value.app_date});
				});
		
				
		
				this.calendarEvents=temp;

			});

		$('#detailsModal').modal('show');
		//this.view_slot = 1;
		console.log('view_slot', this.view_slot);

	}

	setCancelMode(val){
		this.cancelMode = val;
		
	}
	setGroupCancelMode(index, val) {
		//@ts-ignore
		this.groupSlots.group[index].cancelMode = val;
	}

	setCancelUpcoming(){
		if (this.cancelUpcoming == 0 ) {
			this.cancelUpcoming =1;
		} else {
			this.cancelUpcoming = 0;
		}


	}
	setCancellation(val, from) {
		this.cancelSlotId = val;
		this.cancelFrom = from;
		console.log('cancel');
	}

	confirmCancel(){

		console.log(this.cancelSlotId);
		var data = {
			//@ts-ignore
			"appointment_id": this.cancelSlotId,
			"cancellation_mode": this.cancelMode,
			"cancel_upcoming":this.cancelUpcoming,
		}

		

		
		$('.btn-cancel-confirm').attr('disabled', 'true');
		$('.btn-cancel-confirm').text('loading.');

		
		
		this.dataService.postCancelAppointment(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        //clear data
		        this.cancelMode = 0;
		        this.cancelUpcoming = 0;
		        //@ts-ignore
		        this.selectedSlot.is_cancelled = 1;
		        $('.btn-cancel-confirm').removeAttr('disabled');
				$('.btn-cancel-confirm').text('Confirm Cancel');
				
				if(this.cancelFrom == '1') {
					//@ts-ignore
					this.viewVisit(this.cancelSlotId);	

				}else{
					//@ts-ignore
					console.log(this.selectedSlot.slot_id + "-" + this.selectedSlot.show_group + " - " + this.selectedSlot.appointment_id )
					//this.viewSlot(this.selectedSlot.slot_id, 0,0, this.selectedSlot.show_group, this.selectedSlot.appointment_id);
				}

				//this.loadDate(this.date);
				this.getAppointments();

				$('#cancelModal').modal('hide');
		        
	      	} else {
	        	alert(res.msg);
	      	}

		});

		
		
		
		
	}

	cancelFromSlot(visit_id, mode){
		var cnf = confirm("Are you sure you want to cancel this appointment?");

		if (cnf) {
			var data = {
				//@ts-ignore
				"appointment_id": visit_id,
				"cancellation_mode": mode,
				"cancel_upcoming":0,
			}

			this.dataService.postCancelAppointment(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        
		        //this.loadDate(this.date);
		        this.getAppointments();
		        this.viewSlot(this.openSlot.slot_id, this.openSlot.therapist_id,0, 1, 0);
				
		        
	      	} else {
	        	alert(res.msg);
	      	}

		});
		}

	}

	confirmGroupCancel(index){
		var data = {
			//@ts-ignore
			"appointment_id": this.groupSlots.group[index].id,
			//@ts-ignore
			"cancellation_mode": this.groupSlots.group[index].cancelMode,
			"cancel_upcoming": this.cancelUpcoming,
			"clear_field":1,
		}

		

		
		$('.btn-cancel-confirm').attr('disabled', 'true');
		$('.btn-cancel-confirm').text('loading.');
		
		
		
		this.dataService.postCancelAppointment(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        //this.loadDate(this.date);
		        this.getAppointments();

		        $('.btn-cancel-confirm').removeAttr('disabled');
				$('.btn-cancel-confirm').text('Confirm Cancel');
				//@ts-ignore
				this.showDetails(this.selectedSlot.id,0,0);
		        
	      	} else {
	        	alert(res.msg);
	      	}

		});
		

		
		
		
		
	}

	markPresent(id) {
    	
	    var data = {"appointment_id":id};
	    this.dataService.postMarkPresent(data).subscribe( (res:any) => {
	        if(res.status == 0) {
	        	//@ts-ignore
	            this.viewVisit(id);
	       		//this.loadDate(this.date);     
	       		this.getAppointments();
	          } else {
	            alert(res.msg);
	          }
	      } );

	    

	}

	markAbsent(id){
		
	    var data  = {"id":id};
	    this.dataService.postMarkAbsent(data).subscribe( (res: any) => {
	      if(res.status == 0){
	        this.viewVisit(id);
       		this.getAppointments();
	      } else {
	        alert(res.msg);
	      }

	    });
	}

	markDischarge(id,val) {
    	console.log(id);
	    var data = {"appointment_id":id, "value":val};
	    if (val == 3) {
	    	var msg = "Are you sure you want to discharge this patient?"
	    } else if(val == 4) {
	    	var msg = "Are you sure you want to mark this patient discontinued?"
	    }
	    var cnf = confirm(msg);

	    if (cnf) {
	    	this.dataService.postMarkDischarge(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            this.viewVisit(id);
		       		//this.loadDate(this.date);     
		       		this.getAppointments();
		          } else {
		            alert(res.msg);
		          }
		    });
	    }

	    

	}


	  clearSlot() {

	  	var cnf = confirm("Are you sure you want to clear this slot?");

	  	if (cnf) {
	  		//@ts-ignore
	  		var data = {"appointment_id": this.groupSlots.group[0].id };
		  	this.dataService.postClearSlot(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            this.showDetails(this.selectedSlot.id,0,0);
		       		//this.loadDate(this.date);     
		       		this.getAppointments();
		       		$('#detailsModal').modal('hide');
		          } else {
		            alert(res.msg);
		          }
		      } );
	  	}


	  	


	  }

	  loadConsultations(){

		//this.selectedTherapist = id;	  	
	  	this.dataService.getConsultations(this.date, this.selectedDept).subscribe( (data: any[] )=> {
			this.consultations = data;
			
			
		} );
		
	  }

	  submitConsultation(){

	  	var data = {
	  		"patient_id": $('#inputConsultation').attr('data-patient'),
	  		"date":this.date,
	  		"consultant":this.selectedTherapist,
	  		"type": $('#inputConsultation').attr('data-type'),
			"dept_id": this.selectedDept,
	  	};

	  	this.dataService.postConsultation(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            alert(res.msg);
		       		this.loadDate(this.date);     

		       		this.loadConsultations();
		       		$('#inputConsultation').val('');
		       		$('#inputConsultation').attr('data-patient', 0);
		       		$('#addAppointment').modal('hide');
		          } else {
		            alert(res.msg);
		          }
		      } );

	  }

	  submitConsultation2(){

	  	var data = {
	  		"patient_id": $('#inputConsultation2').attr('data-patient'),
	  		"date":this.date,
	  		//@ts-ignore
	  		"consultant":this.data.cArray[0].id,
	  		"type": $('#inputConsultation2').attr('data-type'),
			"dept_id": this.selectedDept,
	  	};

	  	this.dataService.postConsultation(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            this.loadDate(this.date);     
		       		this.loadConsultations();
		       		$('#inputConsultation2').val('');
		       		$('#inputConsultation2').attr('data-patient', 0);
		       		
		          } else {
		            alert(res.msg);
		          }
		      } );
	  	
	  }

	  cancelConsultation(id){
	  	var cnf = confirm("Are you sure you want to cancel this consultation?");

	  	if(cnf){

	  		var data =  {"id":id};

	  		this.dataService.postCancelConsultation(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            
		       		this.loadDate(this.date);     
		       		this.loadConsultations();
		       		
		          } else {
		            alert(res.msg);
		          }
		      } );
	  	}

	  }

	  print(){
	  	window.print();
	  }

	  setCancelView(val){
	  	this.cancelView = val;
	  	//this.loadDate(this.date);
	  	this.getAppointments();
	  }

	  selectRegNo(){
	      $('#inputRegNo').val(this.nextReg);
	  }

	  selectMonth(month){
	  	this.selectedMonth = month;
	  	$('#collapseExample').collapse('show');
	  }
	  
	  switchAppointmentsMode(mode){
	  		this.appointmentsMode = mode;
	  		if (mode==1) {
	  			this.other_appointments = this.selectedVisit.upcoming;
	  			this.other_months = this.selectedVisit.upcoming_months;
	  			
	  		}else if(mode==2){
	  			this.other_appointments = this.selectedVisit.past;
	  			this.other_months = this.selectedVisit.past_months;

	  		}
	  }

	  getAppointments() {
	  	
	  	var date = this.date;
	  	var cancelView = this.cancelView;
	  	var context = this;
	  	//@ts-ignore
	  	this.data.cArray.forEach(function(item, index){
	  		
	  		context.dataService.getAppointments(date, item.id, cancelView, context.selectedDept).subscribe( (data: any["selected"] )=> {
				
				//console.log(data);
				if (data.status == 0) {
					item.beforeBreak = data.docSlots;
					if(item.show == 0){
						item.show = data.show;
					}
					
				}
				
			});
	  		
	  	});
	  	

	  }

	  addMoreAppointments() {

	  	$('#btn-more-appointments').attr('disabled', 'true');
	  	$('#btn-more-appointments').text('Submitting...');

	  	var data = {
	  			"appointment_id":this.selectedVisit.appointment_id,
	  			"repeat_type":this.repeatType,
	  			"repeat_count":this.repeatCount,
	  		};

	  	

	  	this.dataService.postMoreAppointments(data).subscribe( (res:any) => {
	      	$('#btn-more-appointments').removeAttr('disabled');
	      	$('#btn-more-appointments').text('Submit');
	      	this.repeatType = 0;
	        if(res.status == 0) {
	          alert(res.msg);
	          
	          //@ts-ignore
	          
	          
	          //this.loadDate(this.date);
	          this.getAppointments();
	          this.viewVisit(data.appointment_id);
	        } else {
	          alert(res.msg);
	        }
	      });
	  	
	  }

	  
	  closePatientPop(){
	  	this.view_slot = 0;
	  }
	  
	  
	selectNote(note){
		this.selectedNote = note;
	}
	

	submitEditNote(data){
		data.note_id = this.selectedNote.id;

		this.dataService.postEditScheduleNote(data).subscribe( (res:any) => {
			
		  if(res.status == 0) {
			$("#modal-edit-note").modal("hide");
			
		  } else {
			alert(res.msg);
		  }
		});

	}

}

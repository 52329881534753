import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './dashboard/home/home.component';
import { PatientComponent } from './dashboard/patient/patient.component';
import { OverviewComponent } from './dashboard/patient/overview/overview.component';
import { ScheduleComponent } from './dashboard/schedule/schedule.component';
import { PatientsListComponent } from './dashboard/patients-list/patients-list.component';
import { LoginComponent } from './login/login.component';
import { ReceptionistHomeComponent } from './dashboard/receptionist-home/receptionist-home.component';
import { PersonalDetailsComponent } from './dashboard/patient/personal-details/personal-details.component';
import { EnquiryComponent } from './dashboard/enquiry/enquiry.component';
import { BillingComponent } from './dashboard/billing/billing.component';
import { NotificationComponent } from './dashboard/notification/notification.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { EventsComponent } from './dashboard/events/events.component';
import { SymptomsDiagnosisComponent } from './dashboard/patient/symptoms-diagnosis/symptoms-diagnosis.component';
import { IndividualScheduleComponent } from './dashboard/schedule-individual/schedule-individual.component';
import { ViewerComponent } from './viewer/viewer.component';
import { StatisticsComponent } from './dashboard/statistics/statistics.component';
import { StatisticsOverviewComponent } from './dashboard/statistics/overview/overview.component';
import { StatisticsDemographyComponent } from './dashboard/statistics/demography/demography.component';
import { StatisticsRevenueComponent } from './dashboard/statistics/revenue/revenue.component';
import { StatisticsDisabilityComponent } from './dashboard/statistics/disability/disability.component';
import { UsersComponent } from './dashboard/users/users.component';
import { AssessmentComponent } from './dashboard/assessment/assessment.component';
import { HometherapyGoalsComponent } from './dashboard/hometherapy-goals/hometherapy-goals.component';
import { HometherapyComponent } from './dashboard/patient/hometherapy/hometherapy.component';
import { FormComponent } from './dashboard/patient/form/form.component';
import { ResourceComponent } from './dashboard/resource/resource.component';
import { UnifiedFormComponent } from './dashboard/unified-form/unified-form.component';
import { PatientsTodayComponent } from './dashboard/patients-today/patients-today.component';
import { FormBuilderComponent } from './dashboard/form-builder/form-builder.component';
import { HomeprogramComponent } from './dashboard/patient/homeprogram/homeprogram.component';
import { ReportIssueComponent } from './dashboard/report-issue/report-issue.component';
import { AdultAssessmentComponent } from './dashboard/adult-assessment/adult-assessment.component';
import { MyPatientsComponent } from './dashboard/my-patients/my-patients.component';
import { ResourceLibComponent } from './dashboard/resource-lib/resource-lib.component';
import { ChatWindowComponent } from './videochat/chat-window/chat-window.component';
import { RecentActivitiesComponent } from './dashboard/recent-activities/recent-activities.component';
import { DenverComponent } from './dashboard/patient/denver/denver.component';
import { Lest0Component } from './dashboard/patient/lest0/lest0.component';
import { Lest3Component } from './dashboard/patient/lest3/lest3.component';
import { Tdsc0Component } from './dashboard/patient/tdsc0/tdsc0.component';
import { Tdsc3Component } from './dashboard/patient/tdsc3/tdsc3.component';
import { CdcmmComponent } from './dashboard/patient/cdcmm/cdcmm.component';
import { PbsComponent } from './dashboard/patient/pbs/pbs.component';
import { MasComponent } from './dashboard/patient/mas/mas.component';
import { TugComponent } from './dashboard/patient/tug/tug.component';
import { AsdChecklistComponent } from './dashboard/patient/asd-checklist/asd-checklist.component';
import { DieticsComponent } from './dashboard/patient/dietics/dietics.component';
import { PersonalComponent } from './dashboard/patient/dietics/personal/personal.component';
import { RosComponent } from './dashboard/patient/dietics/ros/ros.component';
import { Eat10Component } from './dashboard/patient/dietics/eat10/eat10.component';
import { AnthropometricComponent } from './dashboard/patient/dietics/anthropometric/anthropometric.component';
import { BiochemComponent } from './dashboard/patient/dietics/biochem/biochem.component';
import { FfqComponent } from './dashboard/patient/dietics/ffq/ffq.component';
import { FinalComponent } from './dashboard/patient/dietics/final/final.component';
import { IddsiComponent } from './dashboard/patient/dietics/iddsi/iddsi.component';
import { AudioEvaluationComponent } from './dashboard/patient/audio-evaluation/audio-evaluation.component';
import { GrowChartComponent } from './dashboard/patient/grow-chart/grow-chart.component';
import { DishchargeFormComponent } from './dashboard/patient/dishcharge-form/dishcharge-form.component';
import { UifComponent } from './dashboard/patient/uif/uif.component';
import { TagsComponent } from './dashboard/tags/tags.component';
import { IndtComponent } from './dashboard/patient/indt/indt.component';
import { AnalyticsComponent } from './dashboard/analytics/analytics.component';
import { SubmissionsComponent } from './dashboard/patient/submissions/submissions.component';
import { AppointmentsComponent } from './dashboard/patient/appointments/appointments.component';
import { StaffDetailsComponent } from './dashboard/staff-details/staff-details.component';
import { StaffDetailsListComponent } from './dashboard/staff-details-list/staff-details-list.component';
import { StaffDetailsViewComponent } from './dashboard/staff-details-view/staff-details-view.component';
import { GenerateBillComponent } from './dashboard/generate-bill/generate-bill.component';
import { BillHeadsComponent } from './dashboard/bill-heads/bill-heads.component';
import { BillComponent } from './dashboard/bill/bill.component';

const routes: Routes = [{
  path:'dashboard', component:DashboardComponent, 
  children:[
        {
          path:'analytics', component:AnalyticsComponent,
        },
        {
          path:'home', component:HomeComponent,
        },
        {
          path:'patient/:id', component:PatientComponent, 
          children:[
            {
              path:'dishcharge-form', component: DishchargeFormComponent
            },
            {
              path:'uif', component: UifComponent
            },
            {
              path:'submissions', component: SubmissionsComponent,
            },
            {
              path:'overview', component:OverviewComponent
            },
            {
              path:'details', component:PersonalDetailsComponent
            },
            {
              path:'growth-chart', component:GrowChartComponent
            },
            {
              path:'symptoms-diagnosis', component:SymptomsDiagnosisComponent
            },
            {
              path:'hometherapy', component:HometherapyComponent
            },
            {
              path:'homeprogram', component:HomeprogramComponent
            },
            {
              path:'form', component:FormComponent
            },
            {
              path:'form/:formid', component:FormComponent
            },
            {
              path:'form/:formid/edit/:submission', component:FormComponent
            },
            {
              path:'denver', component:DenverComponent
            },
            {
              path:'lest-0-3', component:Lest0Component
            },
            {
              path:'lest-3-6', component:Lest3Component
            },
            {
              path:'tdsc-0-3', component:Tdsc0Component
            },
            {
              path:'tdsc-3-6', component:Tdsc3Component
            },
            {
              path:'cdcmm', component:CdcmmComponent
            },
            {
              path:'pbs', component:PbsComponent
            },
            {
              path:'pbs/edit/:submission', component:PbsComponent
            },
            {
              path:'mas', component:MasComponent
            },
            {
              path:'tug', component:TugComponent
            },
            {
              path:'asd-checklist', component:AsdChecklistComponent
            },
            {
              path:'asd-checklist/edit/:submission', component:AsdChecklistComponent
            },
            {
              path:'audio-evaluation', component:AudioEvaluationComponent
            },
            {
              path:'audio-evaluation/:test', component:AudioEvaluationComponent
            },
            {
              path:'indt', component:IndtComponent
            },
            {
              path:'appointments', component:AppointmentsComponent
            },
            {
              path:'dietics', component:DieticsComponent,
              children:[
                {
                  path:'personal', component:PersonalComponent
                },
                {
                  path:'ros', component:RosComponent
                },
                {
                  path:'eat10', component:Eat10Component
                },
                {
                  path:'iddsi', component:IddsiComponent
                },
                {
                  path:'anthropometric', component:AnthropometricComponent
                },
                {
                  path:'biochem', component:BiochemComponent
                },
                {
                  path:'ffq', component:FfqComponent
                },
                {
                  path:'final', component:FinalComponent
                },
              ]
            },
          ]
        },
        {
          path:'tags', component:TagsComponent
        },
        {
          path:'schedule', component:ScheduleComponent
        },
        {
          path:'schedule/:flag', component:ScheduleComponent
        },
        {
          path:'schedule-individual/:id', component:IndividualScheduleComponent
        },
        {
          path:'schedule-individual', component:IndividualScheduleComponent
        },
        {
          path:'enquiry', component:EnquiryComponent
        },
        {
          path:'billing', component:BillingComponent
        },
        {
          path:'generate-bill', component:GenerateBillComponent
        },
        {
          path:'generate-bill/:id', component:GenerateBillComponent
        },
        {
          path:'bill-heads', component:BillHeadsComponent
        },
        {
          path:'bill/:id', component:BillComponent
        },
        {
          path:'patient', component:PatientsListComponent
        },
        {
          path:'patients-today', component:PatientsTodayComponent
        },
        {
          path:'my-patients', component:MyPatientsComponent
        },
        {
          path:'receptionHome', component:ReceptionistHomeComponent
        },
        {
          path:'notification', component:NotificationComponent
        },
        {
          path:'profile', component:ProfileComponent
        },
        {
          path:'staff-details', component:StaffDetailsComponent
        },
        {
          path:'staff-details-list', component:StaffDetailsListComponent
        },
        {
          path:'staff-details-view/:id', component:StaffDetailsViewComponent
        },
        {
          path:'events', component:EventsComponent
        },
        {
          path:'statistics', component:StatisticsComponent, children:[
            {
              path:'overview', component:StatisticsOverviewComponent
            },
            {
              path:'demography', component:StatisticsDemographyComponent
            },
            {
              path:'revenue', component:StatisticsRevenueComponent
            },
            {
              path:'disability', component:StatisticsDisabilityComponent
            }
          ]
        },
        {
          path:'users', component:UsersComponent,
        },
        {
          path:'assessment/:id', component:AssessmentComponent,
        },
        {
          path:'hometherapyGoals', component:HometherapyGoalsComponent,
        },
        
        {
          path:'resource', component:ResourceComponent,
        },
        {
          path:'unified-form/:id', component:UnifiedFormComponent, 
        },
        {
          path:'unified-form', component:UnifiedFormComponent, 
        },
        {
          path:'form-builder', component:FormBuilderComponent, 
        },
        {
          path:'report-issue', component:ReportIssueComponent, 
        },
        {
          path:'adult-assessment', component:AdultAssessmentComponent, 
        },
        {
          path:'recent-updates', component:RecentActivitiesComponent,
        },


      ],
    },
    {
      path:'login', component:LoginComponent
    },
    {
      path:'', component:LoginComponent
    },
    {
      path:'viewer/:id', component:ViewerComponent
    },
    {
      path:'video-call/:id', component:ChatWindowComponent
    },
    {
      path:'video-call/:id/:sessionId', component:ChatWindowComponent
    }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

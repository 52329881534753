import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router) { }

  data:any = {enquiries:[], consultants:[], count:0};
  search="";
  limit=20;
  assignId = 0;
  role_id = localStorage.getItem('role_id');
  ngOnInit() {
  	this.loadEnquiries();

    let context = this;

    $( function() {
      (<any>$("#filter-date" )).datepicker({
          dateFormat: "dd-mm-yy",
          beforeShow: function() {
              setTimeout(function(){
                  $('.ui-datepicker').css('z-index', 99999999999999);
              }, 0);
          },
          onSelect: function (dateText, inst) {
            //context.filterDate(dateText);
            this.focus();
          }
      });
    });

  }

 

  loadEnquiries(){
  	
  	this.dataService.getEnquiries(this.limit, this.search).subscribe( (data: any[] )=> {
  		this.data = data;
      
  	});

  }

  searchEnquiries(search){
    this.search = search;
    this.loadEnquiries();
  }

  loadMore() {
    this.limit +=20;
    this.loadEnquiries();
  }

  deleteEnquiry(id) {
    var cnf = confirm("Are you sure you want to delete this enquiry?");

    if(cnf) {
      var data = {"enquiry_id":id};
      this.dataService.postDeleteEnquiry(data).subscribe( (res:any) => {
        if(res.status == 0) {
            alert(res.msg);
            this.loadEnquiries();
            
            
          } else {
            alert(res.msg);
          }
      } );
    }

  }

  actionAssign(id){
    this.assignId = id;
    
  }

  assignEnquiry(data){
    data.enquiry = this.assignId;


    $('#btn-submit-assign').attr('disabled', 'true');
    $('#btn-submit-assign').text('Submitting');

    this.dataService.postEnquiryAssignment(data).subscribe( (res:any) => {
      $('#btn-submit-assign').removeAttr('disabled');
          $('#btn-submit-assign').text('Submit');
      if(res.status == 0) {
          alert(res.msg);
          console.log("res", res);
          $('#ModalAssign').modal('hide');
          
          this.loadEnquiries();
          
        } else {
          alert(res.msg);
        }
    } );


  }

  filterDate(val) {
    
     this.search = val;
    this.loadEnquiries();
  }

  registerEnquiry(id, name, address, phone, phone2, fields){

    $('#inputName').val(name);
    $('#inputPhone').val(phone);
    $('#inputPhone2').val(phone2);
    $('#inputEid').val(id);
    var arr = fields.split(", ");
    console.log(arr);

    arr.forEach(function(item, index){
      if(item.length > 0){
        $('.treatment-selector li:contains("'+item+'")').addClass('active');
      }
      
    })

  }

  bookSlotEnquiry(e){
    this.dataService.localData.slotEnquiry = e;
    this.router.navigate(['/dashboard/schedule/enquiry']);  
  }

  


}
